import React, { memo } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"
const StuckPixelFix = ({ startRepair }) => {
  return (
    <div className="px-3 prose-sm prose sm:prose lg:prose-lg xl:prose-xl md:px-0">
      <p className="lead">
        Clique no botão <code>Reparar</code> e sua tela ficará preta e você verá
        um quadrado com gradientes nele.Arraste esse quadrado e traga-o sobre
        seus pixels presos e espere assim por 10 a 15 minutos.
      </p>
      <blockquote>
        Não se esqueça de ajustar suas configurações de energia para que a tela
        não desligar.
      </blockquote>
      <p>
        Você pode pressionar{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        para terminar o processo de reparo de pixel preso e sair.
      </p>
      <div className="text-center">
        <PurpleSolidButton text="Repair" onClick={startRepair} />
      </div>
    </div>
  )
}
export default memo(StuckPixelFix)
